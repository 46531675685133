<template>
  <div>
    <v-navigation-drawer permanent app clipped>
      <v-list dense>
        <v-list-item @click="uploadForm = true">
          <v-list-item-title>
            <div class="text-uppercase text-left pl-5">Import Updates</div>
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item-group v-model="listSelection" mandatory>
          <v-list-item v-for="item in navList" :key="item.path">
            <v-list-item-title>
              <div class="text-uppercase text-left pl-5">
                {{ item.name }}
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <router-view
      :sectionInfo="selectedSection"
      v-if="selectedSection"
    ></router-view>
    <v-dialog v-model="uploadForm" width="750">
      <upload-form @close="uploadForm = false"></upload-form>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import { camelCase } from "lodash";
  import UploadForm from "./admin/UploadForm.vue";
  export default {
    components: { UploadForm },
    data: () => ({
      uploadForm: false,
      listSelection: "",
    }),
    computed: {
      navList() {
        return this.$router
          .getRoutes()
          .filter(({ parent }) => parent && parent.path == "/admin")
          .sort()
          .map(({ path, name }) => {
            name = name.replace(" Admin", "");
            let adminTable = camelCase(name);
            if (adminTable == "news") adminTable = "latestNews";
            if (adminTable == "socialMediaIcons") adminTable = "social";
            return { path, name, adminTable };
          });
      },
      selectedSection() {
        return this.navList[this.listSelection];
      },
    },
    watch: {
      selectedSection() {
        if (!this.selectedSection) return;
        if (this.$route.path != this.selectedSection.path)
          this.$router.push(this.selectedSection.path);
      },
    },
    methods: {
      ...mapActions(["getAdminData"]),
    },
    mounted() {
      this.getAdminData();
    },
  };
</script>

<style scoped>
  .v-list-item {
    min-height: 35px;
  }
</style>

<template>
  <div>
    <v-card>
      <v-card-title> Import Updates </v-card-title>
      <v-card-text>
        <v-form-base :schema="schema" :model="model"></v-form-base>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">Cancel</v-btn>
        <v-btn text @click="uploadFile">Upload</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import wretch from "wretch";
  import VFormBase from "vuetify-form-base";
  export default {
    components: { VFormBase },
    data: () => ({
      schema: {
        fileInfo: {
          type: "file",
          label: "Select XLS File",
          col: 12,
        },
      },
      model: {},
    }),
    methods: {
      uploadFile() {
        wretch("/api/admin/import-updates")
          .formData({
            token: this.$store.state.adminToken,
            file: this.model.fileInfo,
          })
          .post()
          .text()
          .then(console.log);
      },
    },
  };
</script>